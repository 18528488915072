import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`An existing instance of chatbot produt on Engagement Cloud (a super admin should be available on Chatbot & Automation Studio).`}</li>
      <li parentName="ul">{`A chat skill with the operator delivery method configured in the Contact Center & Analytics Studio.`}</li>
      <li parentName="ul">{`A chatbot configured on Chatbot & Automation Studio.`}</li>
    </ul>
    <h2>{`New Chatbot Functionalities: Seamless Integration with Engagement Cloud"`}</h2>
    <h3>{`Contact availability in Engagement Cloud`}</h3>
    <p>{`All chatbot contacts are aggregated in the Contacts panel under `}<em parentName="p">{`Contact Source of Data`}</em>{`, with conversation transcripts available for review and analysis.`}</p>
    <ul>
      <li parentName="ul">{`The chatbot contact will be listed on listing and aggregation panel (`}<em parentName="li">{`Dashboards`}</em>{`).`}</li>
      <li parentName="ul"><em parentName="li">{`Bot name`}</em>{` and `}<em parentName="li">{`Nodes used`}</em>{` will be filled in and metrics will be displayed.`}</li>
      <li parentName="ul">{`The display and availability of transcripts for each interaction.`}</li>
    </ul>
    <h3>{`Unified Theme`}</h3>
    <p>{`A new theme ensures visual consistency between the chatbot and live chat.`}</p>
    <ol>
      <li parentName="ol">{`Proceed to `}<em parentName="li">{`Chatbot`}</em>{` and open the chatbot to select the theme.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Configuration`}</em>{`.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`Smartwindow`}</em>{` template to use the theme.`}</li>
    </ol>
    <h3>{`Live Chat CoreMedia Transfer with Context`}</h3>
    <p>{`A new action allows chatbot to live chat transfers in CoreMedia, ensuring the Contact Hangup Origin is correctly mapped with the chatbot CoreMedia ID.`}</p>
    <ol>
      <li parentName="ol">{`Proceed to `}<em parentName="li">{`Chatbot`}</em>{` and open the chatbot to select the theme.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Script`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add action`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Live Chat`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`CoreMedia`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in the `}<em parentName="li">{`Skill`}</em>{` and click `}<em parentName="li">{`Close`}</em>{`. `}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`This skill has to be a chat skill with the operator delivery method and properly configured in the Contact Center & Analytics Studio.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`It is also possible to `}<em parentName="p">{`Associate the visitor information fields between Engagement Studio and Chatbot & Automation Studio, selecting the equivalent fields`}</em>{` selected_ for each of the platforms. `}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "../../../images/enablement/chatbot-integration/livechat_action.png",
        "alt": "livechat_action"
      }}></img></p>
    <h3>{`Business Hours Awareness`}</h3>
    <p>{`The chatbot can notify customers when business hours are ending and live chat may be unavailable.`}</p>
    <p>{`When adding a `}<em parentName="p">{`livechat: CoreMedia`}</em>{` action, it possible to define:`}</p>
    <ul>
      <li parentName="ul">{`what should be the next action when Live Chat is unavailable;`}</li>
      <li parentName="ul">{`an additional information to customer that the end of business hours is approaching, by defining the time period.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "../../../images/enablement/chatbot-integration/business-hours-awareness.png",
        "alt": "business-hours-awareness"
      }}></img></p>
    <h3>{`Integrated Customer Surveys`}</h3>
    <p>{`When a `}<em parentName="p">{`Satisfaction survey`}</em>{` is associated with a chat skill, the chatbot can use the same customer survey to get customer feedback about his experience.`}</p>
    <ol>
      <li parentName="ol">{`Create a Survey on Engagement Studio.`}</li>
      <li parentName="ol">{`Add the survey create to `}<em parentName="li">{`Survey`}</em>{` > `}<em parentName="li">{`Client satisfaction`}</em>{` > `}<em parentName="li">{`Satisfaction survey`}</em>{` for all the languages needed.`}</li>
      <li parentName="ol">{`Proceed to `}<em parentName="li">{`Chatbot`}</em>{` and open the chatbot to select the theme.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Script`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add action`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Ask: Engagement cloud satisfation level`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in the `}<em parentName="li">{`Skill`}</em>{` and click `}<em parentName="li">{`Close`}</em>{`. `}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`The responses will be accessible in the Contact Center & Analytics Studio Listing panels > Contact `}<em parentName="p">{`Source of data`}</em>{` > `}<em parentName="p">{`Customer Review`}</em>{` aggregator field.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`To make all these funcionalities available, chatbot needs to be `}<em parentName="p">{`Publish and Save`}</em>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      